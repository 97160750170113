import * as React from 'react';
import { Paper, Container, Box, Typography } from '@mui/material';
import logo from '../photos/logo.png';


export const Footer = () => {
    return (
        <Paper sx={{marginTop: 'calc(10% + 60px)',
            width: '100%',
            bottom: 0,
        }} component="footer" square variant="outlined">
            <Container maxWidth="lg">
                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        my:1
                    }}
                >
                    <div>
                        <img src={logo} width={75} height={30} alt="Logo" />
                    </div>
                </Box>
  
                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        mb: 2,
                    }}
                >
                    <Typography variant="caption" color="initial">
                        Copyright ©2022
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
};