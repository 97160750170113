import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#178555'
        },
        secondary: {
            main: '#FFFFFF'
        },
    },
    components: {
    // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                //disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
    },
});