import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, useMediaQuery } from '@mui/material';
import copperSlate from '../photos/copper_slate.jpg';
import architectPanels from '../photos/architect_panels.jpg';
import singlePly from '../photos/single_ply.png';
import { theme } from '../App.theme';

export const Specialty = () => {
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    const colSize = React.useMemo(() => {
        return sm ? 12 : 4;
    }, [sm]);

    return (
        <Grid container justifyContent='center' direction='row' sx={{textAlign: 'center'}} spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h2" gutterBottom>
                    Specialty Work
                </Typography>
            </Grid>
            <Grid item xs={colSize}>
                <Card sx={{ maxWidth: 345, margin: 'auto', maxHeight: 320 }}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={copperSlate}
                        alt="custom metal roofing"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Custom Metal and Slate
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        Custom fabricated sheet metal including copper, steel and aluminum roof and wall panels, valleys and gutters. Custom slate roofing.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={colSize}>
                <Card sx={{ maxWidth: 345, margin: 'auto', maxHeight: 320   }}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={architectPanels}
                        alt="architectural panels"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Architectural Panels
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        Economical steel and aluminum panels, standing seam panels, multiple colors to choose from with lifetime kynar 500 finish.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={colSize}>
                <Card sx={{ maxWidth: 345, margin: 'auto', maxHeight: 320   }}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={singlePly}
                        alt="single ply roofing"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Commercial Single-Ply
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        Commercial flat roof systems including Thermoplastic Polyolefin (TPO) and Ethylene Propylene Diene Monomer (EPDM).
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
