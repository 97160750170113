import * as React from 'react';
import { Box, Grid, Typography, ImageListItem, ImageListItemBar, useMediaQuery } from '@mui/material';
import { theme } from '../App.theme';

interface FeaturetteProps {
    imgSrc: string;
    imgAlt: string;
    imgTitle: string;
    imgLeft: boolean;
    title: string;
    titleHighlight: string;
    body: string;
}

export const Featurette = (props: FeaturetteProps) => {
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    const imageMargin = React.useMemo(() => {
        return sm ? '0px' : '10px';
    }, [sm]);

    const title = (size: number) => {
        return (
            <Grid item xs={size}>
                <Typography variant="h2" gutterBottom>
                    {props.title} <span style={{color: '#178555'}}>{props.titleHighlight}</span>
                </Typography>
            </Grid>
        );
    };

    const body = (size: number) => {
        return (
            <Grid item xs={size} sx={{ textAlign: 'left'}}>
                <Typography variant='body1' gutterBottom>
                    {props.body}
                </Typography>
            </Grid>
        );
    };

    const image = (size: number) => {
        return (
            <Grid item xs={size}>
                <ImageListItem sx={{marginLeft: imageMargin}}>
                    <img
                        style={{minHeight: '200px', minWidth: '260px'}}
                        src={props.imgSrc}
                        alt={props.imgAlt}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        style={{minWidth: '260px'}}
                        title={props.imgTitle}
                    />
                </ImageListItem>
            </Grid>
        );
    };

    return (
        <Box sx={{ textAlign: 'center', marginTop: '25px', margin: '15px'}}>
            <Grid container columnSpacing={4}>
                {sm && title(12)}
                {sm && body(12)}
                {sm && image(12)}
                {!sm && props.imgLeft &&
                    <>
                        {image(4)}
                        <Grid item container xs={8}>
                            {title(12)}
                            {body(12)}
                        </Grid>
                    </>
                }
                {!sm && !props.imgLeft &&
                    <>
                        <Grid item container xs={8}>
                            {title(12)}
                            {body(12)}
                        </Grid>
                        {image(4)}
                    </>
                }
            </Grid>
        </Box>
    );
};