import * as React from 'react';
import { AppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Button, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../photos/logo.png';
import { HashLink } from 'react-router-hash-link';
import { theme } from '../App.theme';

export const NavHeight = 100;

const pages = [{name: 'Gallery', href: '/gallery'}, {name: 'Contact', href: '/#ContactForm'}];

export const Nav = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    return (
        <>
            <AppBar position="sticky" color='secondary' sx={{height: NavHeight}}>
                <Box sx={{height: '30px', width: '100%', backgroundColor: theme.palette.primary.main}}>
                    <Grid container sx={{textAlign: 'center'}}>
                        <Grid item xs={6}>
                            <Typography variant='body1' color={'secondary'}>10% OFF your first repair!</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color={'secondary'}>
                            Call now! &nbsp;
                                <Typography
                                    component='a'
                                    href='tel:7815514442'
                                    className='numberLink'
                                >
                                    <b>781-551-4442</b>
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' }
                            }}
                        >
                            <img src={logo} alt='Logo' height={`${NavHeight - 30}px`}/>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="navigation menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="primary"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        <HashLink smooth to={page.href} style={{ textDecoration: 'none'}}>
                                            <Button color='primary'>{page.name}</Button>
                                        </HashLink>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                            }}
                        >
                            <img src={logo} alt='Logo' height={`${NavHeight - 30}px`}/>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <HashLink key={page.name} smooth to={page.href} style={{ textDecoration: 'none'}}>
                                    <Button
                                        color='primary'
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'primary', display: 'block' }}
                                    >
                                        {page.name}
                                    </Button>
                                </HashLink>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};