import * as React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Gallery from 'react-photo-gallery';
import { photos } from '../photos/gallery/gallery';


const ModalGatewayNew: React.FC<{ children: React.ReactNode }> = ModalGateway as any;

export const ImageGallery = () => {
    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

    const openLightbox = React.useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    
    return (
        <div>
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGatewayNew>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage} views={photos.map(x => ({
                                ...x,
                                source: x.src,
                                caption: null
                            }))}
                        />
                    </Modal>
                ) : <></>}
            </ModalGatewayNew>
        </div>
    );
};