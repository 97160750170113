import * as React from 'react';
import { Divider } from '@mui/material';
import { ContactForm } from '../ContactForm/ContactForm';
import { Specialty } from '../Specialty/Specialty';
import { Featurette } from './Featurette';
import { Landing } from './Landing';
import firstFeaturette from '../photos/first_featurette.jpg';
import secondFeaturette from '../photos/saintmarks/image7.jpg';
import thirdFeaturette from '../photos/green_building.jpg';

export const Main = () => {
    return (
        <>
            <Landing />
            <Featurette
                imgSrc={firstFeaturette}
                imgAlt={'picture of will, bill and peter walsh'}
                imgTitle={'Will | Bill | Peter Walsh'}
                imgLeft={true}
                title={'Third Generation'}
                titleHighlight={'Roofing'}
                body={'We are a 3rd generation family-run business that opened its doors in 1946. Originally starting out as a tar and gravel roofing business, we grew, expanded and now specialize in large commercial single-ply roofing systems, architectural siding panels, custom copper and slate, as well as high-end residential. Whether a commercial realtor, manufacturer, school, contractor or homeowner, W.P. Walsh Associates is here to service your roofing and sheet metal needs. What sets us apart is our commitment to quality and dedication to customer service. We would be happy to talk today to schedule a consultation.'}
            />
            <Divider sx={{marginTop: '25px', marginBottom: '25px'}} variant="middle"/>
            <Featurette
                imgSrc={secondFeaturette}
                imgAlt={'saint mark\'s school'}
                imgTitle={'Saint Mark\'s School'}
                imgLeft={false}
                title={'Commercial'}
                titleHighlight={'Contractor'}
                body={'Whether you’re a small business, school, manufacturer, airport, etc, we are here for all of your commercial roofing needs. We offer single-ply flat roofing systems, including TPO and EPDM, as well as decorative architectural roofing and wall panels. Your business will be protected rain and shine with our quality service and materials.'}
            />
            <Divider sx={{marginTop: '25px', marginBottom: '25px'}} variant="middle"/>
            <Featurette
                imgSrc={thirdFeaturette}
                imgAlt={'residential work'}
                imgTitle={'Residential Work'}
                imgLeft={true}
                title={'High-End'}
                titleHighlight={'Residential'}
                body={'For our residential clients, we offer a variety of services including roofing of any kind (flat roofing, built-up, slate, shingle and metal), decorative custom roofing and wall panels, siding, light carpentry inside and out, and replacement doors and windows. Our services include repairs and a ten year warranty. What sets us apart is our commitment to quality services, and our willingness to see your custom project to its completion.'}
            />
            <Divider sx={{marginTop: '25px', marginBottom: '25px'}} variant="middle"/>
            <Specialty />
            <Divider sx={{marginTop: '25px', marginBottom: '25px'}} variant="middle"/>
            <ContactForm />
        </>
    );
};