import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import './App.css';
import { theme } from './App.theme';
import { Nav } from './Nav/Nav';
import { Footer } from './Nav/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Main } from './Landing/Main';
import { ImageGallery } from './Gallery/ImageGallery';
import { Popup } from './Popup/Popup';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <Popup />
            <BrowserRouter>
                <Nav />
                <Routes>
                    <Route path='/' element={<Main />} />
                    <Route path='/gallery' element={<ImageGallery />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </ThemeProvider>
    );
}

export default App;
