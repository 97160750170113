import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { EmailMethod } from '../types/enums/EmailMethod';
import { emailRegex } from '../Popup/Popup';


interface FormData {
    email: string;
    name?: string;
    info?: string;
}

const EmptyData: FormData = {
    email: '',
    name: '',
    info: ''
};

export const ContactForm = () => {
    const theme = useTheme();
    const [formData, setFormData] = React.useState<FormData>(EmptyData);
    const [emailError, setEmailError] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const emailUpdate = (e: string) => {
        setFormData({...formData, email: e});
        if (emailRegex.test(e)){
            setEmailError('');
        }
        else{
            setEmailError('Please enter a valid email.');
        }
    };

    return (
        <Box
            sx={{ 
                margin: 'auto',
                marginTop: '75px',
                width: '75%',
                padding: '25px',
                border: `1px solid ${theme.palette.primary.main}`
            }}
            id='ContactForm'
        >
            <Grid container direction='row' spacing={4} justifyContent='center'>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <Typography variant="h4" gutterBottom>
                        Complete this form for a free quote!
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <TextField
                        sx={{width: '90%'}}
                        id="email-input"
                        label="Email"
                        variant="outlined"
                        required
                        placeholder='name@example.com'
                        helperText={emailError}
                        error={emailError === '' ? false : true}
                        value={formData.email}
                        onChange={(e) => emailUpdate(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <TextField
                        sx={{width: '90%'}}
                        id="email-input"
                        label="Name"
                        variant="outlined"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <TextField
                        sx={{width: '90%'}}
                        id="email-input"
                        label="Project Information"
                        variant="outlined"
                        multiline
                        placeholder='Enter a message with as much detail as possible about your project'
                        maxRows={4}
                        value={formData.info}
                        onChange={(e) => setFormData({ ...formData, info: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <LoadingButton
                        loading={loading}
                        onClick={async () => {
                            setLoading(true);
                            const d = await axios.post('email', {...formData, method: EmailMethod.ContactForm});
                            alert(d.data);
                            setLoading(false);
                        }}
                        sx={{width: '90%'}}
                        variant="contained"
                        disabled={emailError !== '' || formData.email === ''}
                    >
                        Send
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
};
