import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, Fade, Grid, Modal, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { EmailCaptureTemplate } from '../types/EmailCaptureTemplate';
import { EmailMethod } from '../types/enums/EmailMethod';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');

export const Popup = () => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [emailTemplates, setEmailTemplates] = React.useState<EmailCaptureTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState<EmailCaptureTemplate>(null);

    React.useEffect(() => {
        axios.get('email').then(d => setEmailTemplates(d.data));
        return () => setSelectedTemplate(null);
    }, []);

    React.useEffect(() => {
        if (selectedTemplate) {
            const interval = setInterval(() => {
                clearInterval(interval);
                handleOpen();
            }, 2000);
        }
    }, [selectedTemplate]);

    React.useEffect(() => {
        if (emailTemplates.length > 0) {
            const min = 0;
            const max = emailTemplates.length - 1;
            const index = Math.floor(Math.random() * (max - min + 1) + min);
            setSelectedTemplate(emailTemplates[index]);
        }
    }, [emailTemplates]);

    const emailUpdate = (e: string) => {
        setEmail(e);
        if (emailRegex.test(e)){
            setEmailError('');
        }
        else{
            setEmailError('Please enter a valid email.');
        }
    };
    
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        ...style,
                        minHeight: 450,
                        backgroundImage: `url(${selectedTemplate?.image})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography id="transition-modal-title" variant="h6" component="h2" color='white'>
                                {selectedTemplate?.text}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{width: '90%', backgroundColor: 'white'}}
                                id="email-input"
                                label="Email"
                                variant='filled'
                                required
                                placeholder='name@example.com'
                                helperText={emailError}
                                error={emailError === '' ? false : true}
                                value={email}
                                onChange={(e) => emailUpdate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={loading}
                                sx={{width: '90%'}}
                                onClick={async () => {
                                    setLoading(true);
                                    const d = await axios.post('email', {email: email, method: EmailMethod.PopUp, methodId: selectedTemplate.id});
                                    alert(d.data);
                                    setLoading(false);
                                    handleClose();
                                }}
                                variant='contained'
                                disabled={emailError !== '' || email === ''}
                            >
                                Send
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
};