import photo1 from './photo1.jpg';
import photo2 from './photo2.jpg';
import photo3 from './photo3.jpg';
import photo4 from './photo4.jpg';
import photo5 from './photo5.jpg';
import photo6 from './photo6.jpg';
import photo7 from './photo7.jpg';
import photo8 from './photo8.jpg';
import photo9 from './photo9.jpg';
import photo10 from './photo10.jpg';
import photo11 from './photo11.jpg';
import photo12 from './photo12.jpg';
import photo13 from './photo13.jpg';
import photo14 from './photo14.jpg';
import photo15 from './photo15.jpg';
import photo16 from './photo16.jpg';
import photo17 from './photo17.jpg';
import photo18 from './photo18.jpg';
import photo19 from './photo19.jpg';
import photo20 from './photo20.jpg';
import photo21 from './photo21.jpg';
import photo22 from './photo22.jpg';
import photo23 from './photo23.jpg';
import photo24 from './photo24.jpg';
import photo25 from './photo25.jpg';
import photo26 from './photo26.jpg';
import photo27 from './photo27.jpg';
import photo28 from './photo28.jpg';
import photo29 from './photo29.jpg';
import photo30 from './photo30.jpg';
import photo31 from './photo31.jpg';
import photo32 from './photo32.jpg';
import photo33 from './photo33.jpg';
import photo34 from './photo34.jpg';
import photo35 from './photo35.jpg';
import photo36 from './photo36.jpg';
import photo37 from './photo37.jpg';


export const photos = [
    {
        src: photo26,
        width: 4,
        height: 3
    },
    {
        src: photo27,
        width: 1,
        height: 1
    },
    {
        src: photo28,
        width: 3,
        height: 4
    },
    {
        src: photo29,
        width: 3,
        height: 4
    },
    {
        src: photo30,
        width: 3,
        height: 4
    },
    {
        src: photo31,
        width: 4,
        height: 3
    },
    {
        src: photo32,
        width: 3,
        height: 4
    },
    {
        src: photo33,
        width: 4,
        height: 3
    },
    {
        src: photo34,
        width: 4,
        height: 3
    },
    {
        src: photo35,
        width: 3,
        height: 4
    },
    {
        src: photo36,
        width: 4,
        height: 3
    },
    {
        src: photo37,
        width: 4,
        height: 3
    },
    {
        src: photo1,
        width: 4,
        height: 3
    },
    {
        src: photo2,
        width: 1,
        height: 1
    },
    {
        src: photo3,
        width: 3,
        height: 4
    },
    {
        src: photo4,
        width: 3,
        height: 4
    },
    {
        src: photo5,
        width: 3,
        height: 4
    },
    {
        src: photo6,
        width: 4,
        height: 3
    },
    {
        src: photo7,
        width: 3,
        height: 4
    },
    {
        src: photo8,
        width: 4,
        height: 3
    },
    {
        src: photo9,
        width: 4,
        height: 3
    },
    {
        src: photo10,
        width: 4,
        height: 3
    },
    {
        src: photo11,
        width: 4,
        height: 3
    },
    {
        src: photo12,
        width: 1,
        height: 1
    },
    {
        src: photo13,
        width: 3,
        height: 4
    },
    {
        src: photo14,
        width: 3,
        height: 4
    },
    {
        src: photo15,
        width: 3,
        height: 4
    },
    {
        src: photo16,
        width: 4,
        height: 3
    },
    {
        src: photo17,
        width: 3,
        height: 4
    },
    {
        src: photo18,
        width: 4,
        height: 3
    },
    {
        src: photo19,
        width: 4,
        height: 3
    },
    {
        src: photo20,
        width: 4,
        height: 3
    },
    {
        src: photo21,
        width: 1,
        height: 1
    },
    {
        src: photo22,
        width: 3,
        height: 4
    },
    {
        src: photo23,
        width: 3,
        height: 4
    },
    {
        src: photo24,
        width: 3,
        height: 4
    },
    {
        src: photo25,
        width: 4,
        height: 3
    }
];
  