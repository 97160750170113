import * as React from 'react';
import Box from '@mui/material/Box';
import image from '../photos/pete_house_edited.jpg';
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HashLink } from 'react-router-hash-link';

export const Landing = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.up('sm'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    //`calc(100vh - ${NavHeight}px)`
    const css = React.useMemo(() => {
        if(xl){
            return {height: 650, position: 'center'};
        }
        if(md){
            return {height: 550, position: 'bottom'};
        }
        if(sm){
            return {height: 450, position: 'left'};
        }
    }, [sm, md, xl]);

    return (
        <Box 
            sx={{ 
                minHeight: css.height,
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: css.position,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Grid container style={{zIndex: '100'}} sx={{backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: '15px'}}>
                <Grid item xs={12}>
                    <Typography variant='h3' color={'secondary'}>
                            Eastern Massachusetts Roofing Contractors
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' color={'secondary'}>
                            Call us today at &nbsp;
                        <Typography
                            component='a'
                            href='tel:7815514442'
                            className='numberLink'
                        >
                            <b>781-551-4442</b>
                        </Typography>
                                &nbsp;
                                or click here for a free quote!
                    </Typography>
                    <HashLink smooth to={'#ContactForm'} style={{ textDecoration: 'none'}}>
                        <Button variant='contained' sx={{marginTop: '15px'}}>Contact Us!</Button>
                    </HashLink>
                </Grid>
            </Grid>
        </Box>
    );
};